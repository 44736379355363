export const fillAddress = (e) => {
  if (e.indexOf("天津") == 0 && e.indexOf("天津市") != 0) {
    return e.replace("天津", "天津市")
  } else if (e.indexOf("北京") == 0 && e.indexOf("北京市") != 0) {
    return e.replace("北京", "北京市")
  } else if (e.indexOf("重庆") == 0 && e.indexOf("重庆市") != 0) {
    return e.replace("重庆", "重庆市")
  } else if (e.indexOf("上海") == 0 && e.indexOf("上海市") != 0) {
    return e.replace("上海", "上海市")
  }

  // if (e.indexOf('中山市') > -1 && e.indexOf('其他区') == -1) {
  //   return e.replace("中山市", "中山市 其他区")
  // }
  // if (e.indexOf('东莞市') > -1 && e.indexOf('其他区') == -1) {
  //   return e.replace("东莞市", "东莞市 其他区")
  // }
  // if (e.indexOf('儋州市') > -1 && e.indexOf('其他区') == -1) {
  //   return e.replace("儋州市", "儋州市 其他区")
  // }
  // if (e.indexOf('嘉峪关市') > -1 && e.indexOf('其他区') == -1) {
  //   return e.replace("嘉峪关市", "嘉峪关市 其他区")
  // }

  return e
}

export const getString = (str) => {
  if (str.indexOf('（') > -1) {
    const a = str.indexOf('（')
    const b = str.indexOf('）')
    const tar = str.slice(a, b + 1)
    const newTar = tar.replace('，', "")
    return str.replace(tar, newTar)
  } else {
    return str
  }

}

export const platformArr = [
  {
    label: "淘宝/天猫",
    value: 2,
    name: "TB"
  },
  {
    label: "京东",
    value: 3,
    name: "JD"
  },
  {
    label: "拼多多",
    value: 4,
    name: "PDD"
  },
  {
    label: "抖音",
    value: 5,
    name: "DY"
  },
  {
    label: "快手",
    value: 6,
    name: "KS"
  },
  {
    label: "其他",
    value: 1,
    name: "QT"
  },
]

export const flagArr = [
  {
    value: 0,
    label: '灰'
  },
  {
    value: 1,
    label: '红'
  }, {
    value: 2,
    label: '黄'
  }, {
    value: 3,
    label: '绿'
  }, {
    value: 4,
    label: '蓝'
  }, {
    value: 5,
    label: '紫'
  }
]

export const dyFlagArr = [
  {
    value: 0,
    label: '灰'
  },
  {
    value: 1,
    label: '紫'
  }, {
    value: 2,
    label: '青'
  }, {
    value: 3,
    label: '绿'
  }, {
    value: 4,
    label: '橙'
  }, {
    value: 5,
    label: '红'
  }
]